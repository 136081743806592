@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contenido,
.all-content,
.store-content,
.admin-panel,
.checkout-page {
  padding-top: 10px;
  max-width: var(--page-width);
  width: 100%;
  margin: auto;
  /* min-height: 80vh; */
}

/*GENERAL VARIABLES hh8rem*/
:root {
  --main-blue: #43abae;
  --main-green: #b6d33c;
  --main-brown: #ceb19b;
  --main-yellow: #fcd700;
  --main-orange: #ff6700;
  --main-gray: #f1f1f1;
  --text: #707070;
  --page-width: 1270px;
  --header-height: 8rem;
  --normal-padding: 8px 24px;
}

@media (max-width: 720px) {
  :root {
    --header-height: 6rem;
  }
}

.icon-Atras-mvil-allfresco:before {
  font-family: 'icomoon' !important;
  content: "\e915";
}

.icon-Men-movil-allfresco:before {
  font-family: 'icomoon' !important;
  content: "\e90c";
  color: var(--main-blue);
  font-size: 2rem;
}

.icon-Inicio-movil-allfresco:before {
  font-family: 'icomoon' !important;
  content: "\e90e";
  color: var(--main-blue);
  font-size: 2rem;
}

.icon-Perfil-de-usuario-allfresco:before {
  font-family: 'icomoon' !important;
  content: "\e911";
  font-size: 2rem;
  color: #B6D33C;
}

.css-171obm1-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected span::before {
  color: #B6D33C;
}

.icon-Siguiente-allfresco:before {
  content: "\e916";
  font-family: 'icomoon' !important;
}

.icon-Busqueda-allfresco:before {
  content: "\e902";
  font-family: 'icomoon' !important;
  font-size: 1.3rem;
  color: var(--main-green);
}

.icon-canasta-allfresco:before {
  content: "\e914";
  font-family: 'icomoon' !important;
  color: #B6D33C;
  font-size: 2rem;
}

.icon-Bolsa-de-compra:before {
  content: "\e900";
  font-family: 'icomoon' !important;
  font-size: 2rem;
}

.icon-editar-allfresco:before {
  content: "\e907";
  font-family: 'icomoon' !important;
  font-size: 1.5rem;
  color: var(--main-blue);
}

.icon-eliminar-allfresco:before {
  content: "\e908";
  font-family: 'icomoon' !important;
  font-size: 1.5rem;
  color: var(--main-blue);
}
.icon-Check-allfresco:before {
  content: "\e90f";
  font-family: 'icomoon' !important;
  font-size: 2rem;
  color: var(--main-green);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Quicksand', sans-serif !important;
  color: var(--main-blue);
  font-weight: 700 !important;
}

h2 {
  font-size: 1.6em !important;
}

h3 {
  font-size: 1.4em !important;
}

h4 {
  font-size: 1.2em !important;
}

h5 {
  font-size: 1.1rem !important;
}

h6 {
  font-size: 1rem !important;
}

@media (max-width: 720px) {
  h2 {
    font-size: 1.5rem !important;
  }

  h4 {
    font-size: 1rem !important;
  }
}

p {
  font-family: 'Quicksand', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: var(--text);
}

a {
  color: var(--main-color);
  text-decoration: none;
}

/* custom Bottom Nav for test */
.bottomNav {
  display: none;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 50;
}

.header h2 {
  margin-bottom: 0px !important;
}

.MuiTableRow-root {
  border-bottom: 0px;
}

.css-fikjyc-MuiTableCell-root-MuiTablePagination-root:last-child {
  padding: 0;
  width: 100%;
  border-bottom: 0px;
}

/* HEADER BUTTON*/
/* ENDS HEADER BUTTON */

.header .at-top {
  display: flex;
  align-items: center;
}

th img {
  max-height: 48px;
}

.at-top p {
  color: white !important;
}

.TabsProfile {
  width: 100%;
  min-height: 60vh;
}

.j-ma {
  margin: auto;
}

.AppStore {
  width: 177px;
}

.PlayStore {
  width: 200px;
}

.tiendas {
  width: 100%;
}

/*
.MuiGrid-item{
  padding: 1rem 0.25rem;
}*/

/*PRIMARY BUTTON*/
/*MUI CARD*/

.MuiCard-root {
  box-shadow: 0px 5px 10px #0000001f !important;
  border-radius: 10px !important;
}

.product-int {
  padding: 2rem 1.5rem;
}

.MuiCardMedia-root,
.card-image {
  padding: .5rem;
}

.MuiCard-root img {
  object-fit: contain;
  padding: 0rem 1rem;
}

.MuiCard-root h2 {
  margin-bottom: 0px !important;
  font-size: 20px !important;
  color: #43ABAE;
  text-align: center;
  min-height: 60px;
}

.MuiCard-root img {
  object-fit: contain;
  padding: 1rem 0.7rem;
  border-radius: 10px !important;
}

/*
.MuiCard-root button, .MuiButtonGroup-root button{
  border-color: #b99880 !important;
  background-color: #CEB19B !important;
  font-weight: 700;
  margin: 0px !important;
}
.MuiCard-root button:hover, .MuiButtonGroup-root button:hover{
      background-color: #b3937b !important;
}
.MuiButtonGroup-root .qty-btn:hover{
  background-color: var(--main-gray)!important;
  border-color:var(--main-gray);
  color:var(--main-brown)!important;
}
.min-btn,.plus-btn{
  max-width: 40px !important;
}*/
/* CARD RESPONSIVE */

@media (max-width: 720px) {
  .product-int {
    padding: 2rem 0.5rem;
  }

  .MuiCard-root img {
    padding: 0rem 1rem;
    max-height: 100px;
  }
}

/*MUI CARD ENDS*/

/*STORE STYLES/*
/*PRODUCT PAGE STYLES*/

.store-content {
  padding-top: 1rem;
}

.product-details {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.product-details h2,
.product-details h3,
.product-details h4,
.product-details p {
  width: 100%;
}

.product-details h4 {
  color: var(--text);
}

@media (max-width: 960px) {
  .user-box-text {
    display: none;
  }
}

@media (max-width: 720px) {
  .store-content {
    padding-top: 2rem !important;
    gap: 12px;
  }

  .store-content .product-details {
    max-width: 100%
  }

  .store-content img {
    max-width: 100%;
  }

  .user-box-text {
    display: none;
  }
}

.checkout-page h5 {
  text-align: center !important;
}

.store-content h2,
.store-content h3,
.store-content h4 {
  margin-bottom: 0rem;
}

.at-middle {
  max-height: var(--header-height);
  display: flex;
  background-color: white;
  padding: var(--normal-padding) !important;
}

.sub-header,
.footer-c {
  max-width: var(--page-width);
  width: 100%;
  margin: auto;
}

.at-top .sub-header {
  padding: var(--normal-padding);
}

.at-middle .sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--normal-padding);
}

.at-left {
  font-size: 24px;
  flex: 25%;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
}

.zip-code::before {
  font-family: 'icomoon';
  font-size: 26px;
  padding: .5rem;
  content: "\e901";
  color: var(--main-green);
}

.zip-box {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.zip-box p,
.zip-box h5 {
  font-size: 22px !important;
}

.at-center {
  flex: 45%;
  display: flex;
  justify-content: center;
}

.search-box {
  width: 100% !important;
}

.search-icon {
  color: var(--main-green) !important;
  transform: rotate(80deg);
  margin-left: -4px;
  height: 2rem !important;
  width: 2rem !important;
  z-index: 4;
}

.search-icon::before {
  font-family: 'icomoon';
  content: "\e903" !important;
}

.search-box input,
.css-1us5wx,
.campo {
  border: 0px dashed darkgray !important;
  color: var(--text) !important;
  line-height: 2rem;
  border-radius: 10px !important;
  background-color: var(--main-gray) !important;
  max-width: 100%;
}

.search-input input {
  max-width: 100%;
}

.at-right {
  flex: auto;
  display: flex;
  justify-content: flex-end;
}

.user-box {
  display: flex;
  color: var(--main-green);
}

.user-box p,
.user-box li {
  color: var(--main-blue) !important;
}

.user-box .MuiSvgIcon-root {
  height: 2rem;
  width: 2rem;
}

.user-box .MuiBadge-root span {
  background-color: #ff6700 !important;
}

/*TEMPORARY FIX*/

.card-btn-unit:active {
  background-color: #f9f9f9 !important;
}

.btn-join {
  font-family: 'Quicksand', sans-serif;
  font-size: 24px;
  color: white;
  line-height: 32px;
  font-weight: 700;
  background-color: var(--main-green);
  border-radius: 10px;
  margin: 0.5rem;
}

@media (max-width: 600px) {
  .icon-Perfil-de-usuario-allfresco:before {
    color: var(--main-blue);
  }

  /* my work  */
  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .zip-box {
    display: none;
    /* max-width: 30px;
    overflow: hidden; */
  }

  .search-box {
    max-width: fit-content;
    border: 1px solid #43ABAE;
    /* order: 1; */
  }

  .at-left {
    order: 2;
    max-width: fit-content;
    /* border:1px solid #43ABAE; */
  }

  .at-center {
    max-width: 60px;
    order: 1;
    overflow: hidden;
    /* border:1px solid #43ABAE; */
    left: -50px;
  }

  .at-right {
    max-width: fit-content;
    order: 3;
    /* border:1px solid #43ABAE; */
  }

  .user-box-text {
    display: none;
  }

  .bottomNav {
    display: block;
  }

  /* .user-box{
    max-width: 45px;
    overflow: hidden;
  } */


  /* .at-right, .zip-box{
      display:none;
  } */
  /* .search-box{
    margin-left:0px !important;
    margin-right:0px !important;
  } */
  .at-center {
    flex: 50;
    margin-left: 10px;
  }

  .at-middle .sub-header {
    padding: 0px;
  }

  .search-input {
    display: none;
  }

  .contenido {
    min-height: 100vh;
    padding-top: 1rem;
  }

  .footer-c {
    margin-bottom: 60px;
  }
}

.footer-c {
  display: flex;
  justify-content: center;
  bottom: 0;
  position: inherit;
  border-top: 1px solid #f1f1f1;
}

@media (max-width:600px){
  .promo{
    font-size: 14px !important;
  }
}

@keyframes logo-float {
  0%{
    transform: translateY(-50px);
  }
  15%{
    transform: translateY(-30px);
  }
  40%{
    transform: translateY(30px);
  }
  50%{
    transform: translateY(60px);
  }
  60%{
    transform: translateY(35px);
  }
  90%{
    transform: translateY(-30px);
  }
  100%{
    transform: translateY(-50px);
  }
}
@media (prefers-reduced-motion: no-preference) {
  #aguacatin{
    animation: logo-float infinite 15s linear;
  }
}
#aguacate{
  height:auto; 
  width:600px
}
#enchufe{
  height: auto;
  width: 150px;
}
#notFound{
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-grow: 1;
}
#aguacatin{
  position:'relative';
  left:"200px"; 
  rotate:"5deg";
}
#enchufin{
  position:'relative';
  left:"250px";
  top:"200px";
}
@media screen and (max-width:900px) {
  #aguacate{
    width: 250px;
    margin-left: -20px;
  }
  #enchufe{
    margin-left: 70px;
    margin-top: 100px;
    width: 75px;
  }
  #enchufin{
    left: 0px;
  }
  #aguacatin{
    left: 0px;
  }
  #notFound{
    justify-content: center;
    /* background-color: #ff6700; */
    width: 100px;
    height: 300px;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
